@font-face {
  font-family: myFirstFont;
  src: url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
}
body {
  font-family: 'Poppins', sans-serif;
  margin: 0px;
}

:root {
  --swiper-theme-color: #00b3bc;
  --swiper-navigation-size: 24px;
}


.swiper-container {
  /* width: 100%;
  height: 100%; */
}

.vertical-wrapper {
  /* height: 70%; */
}
.vertical-slide{
  margin-bottom: 0px;
}
.center {
  display: flex;
  justify-content: center;
}
.total-wrapper {
  width: 900px;
  height: 95vh;
  max-height: 95vh;
  background-color: #fafafa;
}

@media only screen and (max-width: 800px) {
  .total-wrapper {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
}
.swiper-pagination {
  bottom: 93% !important;
  padding-bottom: 6px;
}
.swiper-pagination-progressbar {
  width: 70% !important;
  left: 15% !important;
  border-radius: 120px;
  margin-top: 24px;
  height: 0px !important;
}
.swiper-pagination-progressbar-fill {
  border-radius: 12px;
}


.swiper-wrapper {
  padding-inline-start: 0;
}
.swiper {
  /* width: 100%;
  height: 100%; */
}


.swiper-slide img {
  /* display: block; */
  
  /* margin-left: auto;
  margin-right: auto; */
  /* width: 100%; */
  /* max-height: 45vh; */
  /* object-fit: cover; */
  /* margin-top: 25px; */
} 
@media only screen and (max-width: 600px) {
  .swiper-slide img {
    width: 100%;
  }
}

h1 {
  font-size: 32px;
}
h2 {
  font-size: 30px;
}
/* .section-slide-wrapper {
  margin: 40px;
  margin-top: 50px;
} */
.article-overview-slide-wrapper {
  margin: 40px;
  margin-top: 10vh;
  display: flex;
  align-items: center;
}
.lottie-scroll {
  width: 80px;
  height: 80px;
}

.module-wrapper {
  /* margin-top: 10px;
  margin-bottom: 10px; */
  /* margin-top: 30vh; */
  display: block;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-size: 1,125rem;
  line-height: 1,75rem;
  background-color: #DFE7EB;

  margin-bottom: 20px;
  margin-right: 5px;;
  padding-left: 20px;
  padding-right: 20px;
  padding-left: 42px;
  padding-right: 42px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 15px;
}

@media only screen and (max-width: 800px) {
  .module-wrapper {
    margin-right: 10px;
  }
}


.opened-module-wrapper {
  background-color: #c5d4db;
}

details {
  cursor: pointer;
}
details[open] summary ~ * {
  animation: sweep .5s ease-in-out;
}

details > summary {
  list-style-type: '';
}

details > summary::before {
  content: '▶️ ';
  /* content:  url(https://uxwing.com/wp-content/themes/uxwing/download/02-arrow-direction/arrows-down.png); */
}

/* */
details[open] > summary::before {
  content: '🔽 ';
}

details {
  width: 100%;
  border-radius: 12px;
  margin: 20px;
  padding: 17px;
  background-color: #e2edf1;
}

@keyframes sweep {
  0%    {opacity: 0; transform: translateX(-10px)}
  100%  {opacity: 1; transform: translateX(0)}
}


.swiper-button-next {
  top: 20px;
  border-color: #00b3bc !important;
  border-style: solid;
  border-width: 3px;
  border-radius: 50px;
  width: 32px !important;
  height: 32px !important;
}

.swiper-button-prev {
  top: 20px;
  border-color: #00b3bc !important;
  border-style: solid;
  border-width: 3px;
  border-radius: 50px;
  width: 32px !important;
  height: 32px !important;
}

.image-section{
  max-width: 300px;
  max-height: 300px;
  flex-grow: 4;
}
.section-img {
  
  /* margin-left: auto;
  margin-right: auto; */
  /* max-height: 45vh; */
  border-radius: 15px;
  width:100%;
  height:100%;
  object-fit: cover;
  overflow: hidden;
}
@media only screen and (max-width: 800px) {
  .section-img {
    border-radius: 0px;

  }
}

.section-img:after {
  content: "";
  padding-bottom: 100%;
}

.image-source {
  padding: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
}

.modules-section  {
  width: 40%;
  height: calc(100vh - 185px);
  overflow-y: scroll;
  overflow-x: hidden;

  flex-grow: 8;
  min-width: 350px;
  padding: 60px;
  padding-top: 0px;
}
@media only screen and (max-width: 800px) {
  .modules-section  {
    height: auto;

    width: 100%;

    flex-grow: 0;
    padding: 0px;
    padding-top: 0px;
    margin-left: 5px;
    margin-right: 5px;

  }
  .image-section {
    width: 100%;
    /* padding-bottom: 60px; */
    max-width: 600px;
    max-width: 100%;
    max-height: 100%;
    flex-grow: 0;
  }
}


.section-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: stretch;
  margin-top: 60px;
}

@media only screen and (max-width: 800px) {
  .section-wrapper {
    display: block;
  }
}
._no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
._no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.article-title {
  color: #001B37;
  font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: normal;
  margin-top: 10px;
  margin-bottom: 5px;
}


@media only screen and (max-width: 800px) {

.article-title {
  color: #001B37;
  font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: normal;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}

}
.section-title {
  background-color: #52899F;
  cursor: default;
}
.section-title-inner {
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.ReactCollapse--collapse {

  max-width: 800px;
  /* border: 1px solid rgba(3, 169, 244, 0.3); */
  border-radius: 10px;
  /* background-color: rgba(100, 255, 100, 0.1); */
  transition: height 500ms;
}

.ReactCollapse--content {
}



.module-title{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  /* line-height: 36px; */
  display: flex;
  align-items: center;
  
  color: #001E29;
  margin-top: 0px;
  margin-bottom: 0px;
    
}
.module-cta {

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  /* line-height: 36px; */
  
  /* identical to box height */
  display: flex;
  align-items: center;

  color: #00b3bc;

  margin-top: 5px;
  margin-bottom: 0px;
}
.module-body {

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  /* line-height: 36px; */
  
  color: #52899F;

  margin-top: 5px;
  margin-bottom: 0px;
}

.header {
    position: sticky;
    top:0;
    padding:1px;
    padding-left: 25px;
    background: #1d4861;
    text-align: left;
    color: white;
    z-index: 500;
}

.settings-button{
  color:#00b3bc !important;
  text-transform: "none" !important;
}

.radio-group{

  color:#00b3bc !important;
}
